<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateSemaforo">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="semaforo.name" class="form-control" id="name" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/semaforos";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      semaforo: {
        name: "",
      },
      semaforoId: this.$route.params.id,
    };
  },
  mounted() {
    this.getSemaforo();
  },
  methods: {
    getSemaforo() {
      if (!this.semaforoId) {
        return;
      }
      find(this.semaforoId).then((response) => {
        this.semaforo = response.data;
      });
    },
    saveOrUpdateSemaforo() {
      if (this.semaforoId) {
        this.updateSemaforo();
      } else {
        this.saveSemaforo();
      }
    },
    saveSemaforo() {
      create(this.semaforo).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/semaforos");
      });
    },
    updateSemaforo() {
      update(this.semaforoId, this.semaforo).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/semaforos");
      });
    },
  },
};
</script>
